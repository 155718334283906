<template>
  <div class="flex justify-between text-sm">
    <div class="flex items-center space-x-3">
      <ZenniIconsIconUserAccount size="xl" class="text-grays-light" />
      <div
        class="flex flex-col"
        :class="{
          'opacity-50': props.unavailable,
        }"
      >
        <span class="font-semibold">{{
          `${props.dependent.recipient?.firstName} ${props.dependent.recipient?.lastName}`
        }}</span>
        <div class="flex flex-col space-x-1 sm:flex-row sm:items-center">
          <span
            class="bg-grays-lighter text-grays-darkest mt-0.5 w-fit rounded-full px-2 py-0.5 text-center"
            :class="{ 'bg-purples-lightest text-purples-dark': amount > 0 }"
          >
            {{
              formatCurrency(amount, 'en-US', {
                currency: 'USD',
                minimumFractionDigits: 2,
              })
            }}
          </span>
          <span class="text-grays-dark whitespace-nowrap"
            ><span class="hidden sm:inline-block">&nbsp;&#8226;&nbsp;</span>
            {{ categoryLabel }}</span
          >
        </div>
      </div>
    </div>
    <ZenniIconsIconCheckMarkSolid
      v-if="props.selected"
      class="text-teal-primary justify-self-end"
      size="xl"
    />
  </div>
</template>

<script setup lang="ts">
import type { CampaignGiftGetResponseBody } from '@/types/redemption'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { computed } from '#imports'
import { useGuestStore } from '~/store/guest'
import type { PropType } from 'vue'

const props = defineProps({
  dependent: {
    type: Object as PropType<CampaignGiftGetResponseBody>,
    required: true,
    default: () => {},
  },
  selected: {
    type: Boolean,
    required: true,
    default: () => false,
  },
  unavailable: {
    type: Boolean,
    required: false,
    default: () => false,
  },
})

const guestStore = useGuestStore()

const categoryLabel = computed<string>(() => {
  const catalogs = props.dependent?.policy?.catalogs || []

  if (catalogs.includes('vision') && catalogs.includes('safety')) {
    return 'Eyeglasses, Sunglasses, & Safety Glasses'
  } else if (catalogs.includes('vision')) {
    return 'Eyeglasses & Sunglasses'
  } else if (catalogs.includes('safety')) {
    return 'Safety Glasses'
  }

  return ''
})

const amount = computed<number>(() =>
  guestStore.calculateGiftAmt(props.dependent),
)
</script>
